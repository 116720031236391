

export default function ReactHighlight() {
    return <div></div>
}

function Code() {
    return <div></div>
}

export {Code} 